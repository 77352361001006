<template>
  <div id="numberInput">
    <div
      v-if="digitNum"
      class="dight-style"
    >
      <caret-up-outlined />
      <span>{{ digitNum }}</span>
    </div>
    <a-input-number
      v-model:value="inputValue"
      :min="min"
      :max="max"
      :formatter="formatter"
      :parser="value => value.replace(/\$\s?|(,*)/g, '')"
      :size="inputSize"
      :style="inputStyle"
      :class="digitNum ? `dight-num-${inputSize}` : ''"
      :placeholder="placeholder"
      :controls="false"
      @focus="focus"
      @change="change"
    />
  </div>
</template>
<script>
import { reactive, toRefs, watch } from 'vue'
import i18n from '@/locale'
export default {
  name: 'NumberInput',
  props: {
    inputNum: {
      type: Number,
      default: () => { return null }
    },
    min: {
      type: Number,
      default: () => { return -Infinity }
    },
    max: {
      type: Number,
      default: () => { return Infinity }
    },
    inputSize: {
      type: String,
      default: () => { return 'middle' }
    },
    inputStyle: {
      type: Object,
      default: () => { return { width: '100%' } }
    },
    placeholder: {
      type: String,
      default: () => { return i18n.global.t('common_text_006') }
    }
  },
  setup (props, ctx) {
    const state = reactive({
      inputValue: null,
      digitNum: null,
      digitShow: false,
      digitMap: {
        4: i18n.global.t('number_text_001'),
        5: i18n.global.t('number_text_002'),
        6: i18n.global.t('number_text_003'),
        7: i18n.global.t('number_text_004'),
        8: i18n.global.t('number_text_005'),
        9: i18n.global.t('number_text_006'),
        10: i18n.global.t('number_text_007'),
        11: i18n.global.t('number_text_008'),
        12: i18n.global.t('number_text_009'),
        13: i18n.global.t('number_text_010'),
        14: i18n.global.t('number_text_011'),
        15: i18n.global.t('number_text_012'),
        16: i18n.global.t('number_text_013')
      }
    })

    watch(() => props.inputNum,
      value => {
        state.inputValue = value
      }
    )

    const change = () => {
      state.digitShow = false
      ctx.emit('update:inputNum', state.inputValue)
      ctx.emit('update')
    }

    const focus = () => {
      state.digitShow = true
      const inputValue = state.inputValue
      state.inputValue = null
      const timer = setInterval(() => {
        clearInterval(timer)
        state.inputValue = inputValue
      }, 1)
    }

    const formatter = (v, info) => {
      state.digitNum = null
      if (v || v === 0) {
        const str = v.toString()
        const splits = str.split('.')
        if (v >= 0) {
          state.digitNum = state.digitMap[splits[0].length]
        } else {
          state.digitNum = state.digitMap[splits[0].length - 1]
        }

        const nStr = str.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        if (!info.userTyping) {
          if (info.input === 'undefined' && state.digitShow) return v
          if (splits && splits[1]) {
            if (splits[1].length < 2) return `${nStr}0`
            return nStr
          }
          return `${nStr}.00`
        }
        return v
      }
    }

    return {
      focus,
      change,
      formatter,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
  #numberInput {
    position: relative;
    .dight-style {
      display: flex;
      align-items: center;
      position: absolute;
      font-size: 12px;
      left: 16px;
      bottom: 0;
      z-index: 10;
    }
    .dight-num-large:deep(.ant-input-number-input) {
      padding-bottom: 12px;
    }
    .dight-num-middle:deep(.ant-input-number-input) {
      padding-bottom: 18px;
    }
  }
</style>
